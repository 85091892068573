<template>
  <v-card>
    <!-- <v-img
          :src="`http://teacademy.ca:8080/api/image/student/${ item.id }`"
          aspect-ratio="2"
          height="300px"
        > -->
    <v-layout
      column
      fill-height
    >
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-spacer />

      <v-card-title class="">
        <div class="display-1 pl-5 pt-5">
          {{ `${ capFirst(item.firstName) } ${ capFirst(item.lastName) }` }}
        </div>
      </v-card-title>
    </v-layout>
    <!-- </v-img> -->

    <v-list two-line>
      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            phone
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.homephone }}</v-list-tile-title>
          <v-list-tile-sub-title>Mobile</v-list-tile-sub-title>
        </v-list-tile-content>

        <!-- <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action> -->
      </v-list-tile>

      <v-list-tile>
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>{{ item.cellphone }}</v-list-tile-title>
          <v-list-tile-sub-title>Work</v-list-tile-sub-title>
        </v-list-tile-content>

        <!-- <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action> -->
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            mail
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.email }}</v-list-tile-title>
          <v-list-tile-sub-title>Personal</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            location_on
          </v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.address }}</v-list-tile-title>
          <v-list-tile-sub-title>
            {{ item.province }}, {{ item.postalCode }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <slot name="action" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ProfileCard',
  components: {
  },
  props: {
    person: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    item() {
      return this.person
    }
  },
  methods: {
    capFirst(v) {
      return v ? v.charAt(0).toUpperCase() + v.slice(1) : ''
    },
  },
}
</script>
