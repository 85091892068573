<template>
  <v-card>
    <v-card-title>
      <span class="headline">Update Info</span>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex
              xs12
              sm6
              md6
            >
              <v-text-field
                v-model="item.companyName"
                label="Company Name"
                :rules="[rules.required, rules.length(0, 50)]"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md6
            >
              <v-text-field
                v-model="item.contactName"
                label="Contact Name"
                :rules="[rules.required, rules.length(0, 50)]"
              />
            </v-flex>
            <!-- <v-flex
              xs12
              sm6
              md4
            >
              <v-text-field
                v-model="item.email"
                label="Email"
                :rules="[rules.required, rules.email]"
              />
            </v-flex> -->
            <v-flex
              xs12
              sm6
              md4
            >
              <v-text-field
                v-model="item.phone"
                label="Phone"
                :rules="[rules.required]"
              />
            </v-flex>
            <v-flex
              xs12
              sm12
              md12
            >
              <v-text-field
                v-model="item.address"
                label="Address"
                :rules="[rules.required, rules.length(0, 100)]"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <slot
        :agent="item"
        :validate="validate"
        name="action"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '@/custom/validate'

export default {
  name: 'AgentForm',
  props: {
    agent: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: []
    }
  },
  computed: {
    item() {
      return { ...this.agent }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.rules = validate.rules
    },
    validate() {
      return this.$refs['form'].validate()
    },
    reset() {
      this.$refs.form.reset()
    }
  }
}
</script>