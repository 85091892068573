<template>
  <v-card>
    <v-card-title>
      <span class="headline">Edit Profile</span>
    </v-card-title>
    <v-form ref="form">
      <v-tabs>
        <v-tab :href="'#basic'">
          Basic Info
        </v-tab>
        <v-tab :href="'#address'">
          Address
        </v-tab>
        <v-tab :href="'#contact'">
          Contact Info
        </v-tab>
        <v-tab-item :value="'basic'">
          <v-card>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs3>
                  <v-text-field
                    v-model="item.lastName"
                    :rules="[
                      rules.required,
                      rules.length(0, 50)
                    ]"
                    :counter="50"
                    class="required"
                    label="Family Name"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="item.middleName"
                    :rules="[
                      rules.length(0, 50)
                    ]"
                    :counter="50"
                    label="Middle Name"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="item.firstName"
                    :rules="[
                      rules.required,
                      rules.length(0, 50)
                    ]"
                    :counter="50"
                    class="required"
                    label="Given Name"
                  />
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="item.preferName"
                    :rules="[
                      rules.length(0, 50)
                    ]"
                    :counter="50"
                    label="Preferred Name"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-menu
                    ref="dobMenu"
                    v-model="dobMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="item.dateOfBirth"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      v-model="item.dateOfBirth"
                      :rules="[
                        rules.required,
                      ]"
                      class="required"
                      label="Date of Birth"
                      placeholder="yyyy-mm-dd"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="item.dateOfBirth"
                      @input="$refs.dobMenu.save(item.dateOfBirth)"
                    />
                  </v-menu>
                </v-flex>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="item.birthCountry"
                    :items="countryOptions"
                    :rules="[
                      rules.required,
                    ]"
                    class="required"
                    item-text="english_desc"
                    item-value="id"
                    label="Country of Birth"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="item.gender"
                    :items="genderOptions"
                    :rules="[
                      rules.required
                    ]"
                    class="required"
                    item-text="text"
                    item-value="value"
                    label="Gender"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-select
                    v-model="item.residentStatus"
                    :items="residentStatusOptions"
                    :rules="[
                      rules.required
                    ]"
                    class="required"
                    item-text="text"
                    item-value="value"
                    label="Status in Canada"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'address'">
          <v-card>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <v-text-field
                    v-model="item.address"
                    :counter="200"
                    :rules="[
                      rules.required,
                      rules.length(0,200)
                    ]"
                    class="required"
                    label="Current Address"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="item.city"
                    :rules="[
                      rules.required,
                      rules.length(0,50)
                    ]"
                    class="required"
                    label="City"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="item.province"
                    :rules="[
                      rules.required,
                      rules.length(0,50)
                    ]"
                    class="required"
                    label="State/Province"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="item.postalCode"
                    :rules="[
                      rules.required,
                      rules.length(0,20)
                    ]"
                    class="required"
                    label="Postal/Zip Code"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="item.country"
                    :items="countryOptions"
                    :rules="[
                      rules.required,
                    ]"
                    class="required"
                    item-text="english_desc"
                    item-value="id"
                    label="Country"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'contact'">
          <v-card>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs6>
                  <v-text-field
                    v-model="item.homephone"
                    :rules="[
                      rules.required,
                    ]"
                    class="required"
                    label="Home Phone"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="item.cellphone"
                    :rules="[
                      rules.required,
                    ]"
                    class="required"
                    label="Mobile"
                  />
                </v-flex>
                <!-- <v-flex xs12>
                  <v-text-field
                    v-model="item.email"
                    :rules="[
                      rules.required,
                      rules.email,
                    ]"
                    class="required"
                    label="Email"
                  />
                </v-flex> -->
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-card-actions>
      <v-spacer />
      <slot
        :person="item"
        :validate="validate"
        name="action"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '@/custom/validate'

export default {
  name: 'ProfileEditForm',
  components: {
  },
  props: {
    person: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: [],
      residentStatusOptions: [
        { text: 'Canadian Citizen', value: '1' },
        { text: 'Permanent Resident', value: '3' },
        { text: 'Visa Student', value: '4' },
        { text: 'Refugee Status', value: '7' },
        { text: 'Other Visa', value: '5' },
      ],
      genderOptions: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' },
      ],
      countryOptions: [],
      dobMenu: false,
    }
  },
  computed: {
    item() {
      return { ...this.person }
    }
  },
  created() {
    this.rules = validate.rules
    this.$store.dispatch('regions/allCountries')
      .then((data) => { this.countryOptions = data })
  },
  methods: {
    validate() {
      return this.$refs['form'].validate()
    }
  }
}
</script>

<style>

</style>