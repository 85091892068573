<template>
  <v-card>
    <v-card-title>
      <span class="headline">Representative Form</span>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs4>
              <v-text-field
                v-model="rep.firstName"
                label="First Name"
                :rules="[
                  rules.required,
                  rules.length(0, 20)
                ]"
                :counter="20"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="rep.lastName"
                label="Last Name"
                :rules="[
                  rules.required,
                  rules.length(0, 20)
                ]"
                :counter="20"
              />
            </v-flex>
            <!-- <v-flex xs4>
              <v-text-field
                v-model="rep.email"
                label="Email"
                :rules="[
                  rules.required,
                  rules.email
                ]"
              />
            </v-flex> -->
            <v-flex xs6>
              <v-text-field
                v-model="rep.phone"
                label="Phone"
                :rules="[
                  rules.required
                ]"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <slot 
        name="action" 
        :rep="rep"
        :validate="validate" 
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '@/custom/validate'

export default {
  name: 'RepForm',
  props: {
    representative: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      rules: [],
    }
  },
  computed: {
    rep() {
      return { ...this.representative }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.rules = validate.rules
    },
    validate() {
      return this.$refs['form'].validate()
    }
  },
}
</script>

<style>

</style>
