<template>
  <v-card>
    <v-layout
      column
      fill-height
    >
      <v-spacer />
      <v-card-title class="">
        <div class="display-1 pl-5 pt-5">
          {{ `${ capFirst(item.companyName) }` }}
        </div>
      </v-card-title>
    </v-layout>
    <!-- </v-img> -->

    <v-list two-line>
      <v-list-tile>
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>{{ item.agentId }}</v-list-tile-title>
          <v-list-tile-sub-title>Company ID</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            phone
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.phone }}</v-list-tile-title>
          <v-list-tile-sub-title>Telephone</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      
      <v-list-tile>
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>{{ item.contactName }}</v-list-tile-title>
          <v-list-tile-sub-title>Contact Name</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            mail
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.email }}</v-list-tile-title>
          <v-list-tile-sub-title>Contact Email</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile>
        <v-list-tile-action>
          <v-icon color="indigo">
            location_on
          </v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.address }}</v-list-tile-title>
          <v-list-tile-sub-title>Address</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <slot name="action" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AgentCard',
  components: {
  },
  props: {
    agent: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    item() {
      return this.agent
    }
  },
  methods: {
    capFirst(v) {
      return v ? v.charAt(0).toUpperCase() + v.slice(1) : ''
    },
  },
}
</script>
