<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <agent-profile v-if="user.privilege === 'agent'" />
        <rep-profile v-else-if="user.privilege === 'rep'" />
        <member-profile v-else />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AgentProfile from '@/components/agent/AgentProfile.vue'
import RepProfile from '@/components/representative/RepProfile.vue'
import MemberProfile from '@/components/member/MemberProfile.vue'

export default {
  name: 'Profile',
  components: {
    'agent-profile': AgentProfile,
    'rep-profile': RepProfile,
    'member-profile': MemberProfile
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
  },
}
</script>

<style>

</style>