<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <h2>{{ heading }}</h2>
        <span class="subheading">
          {{ subheading }}
        </span>
        <v-divider class="my-3" />
      </v-flex>
      <v-flex xs6>
        <rep-card 
          :rep="userInfo" 
          :mode="'deco'"
        >
          <template v-slot:action>
            <v-btn @click="infoEditDialog = true">
              Edit
            </v-btn>
          </template>
        </rep-card>
      </v-flex>
      <v-dialog 
        v-model="infoEditDialog" 
        max-width="850px"
        min-height="400px"
      >
        <rep-form :representative="userInfo">
          <template v-slot:action="{ rep, validate }">
            <v-btn 
              color="default" 
              @click="infoEditDialog = false"
            >
              Close
            </v-btn>
            <v-btn 
              color="primary" 
              @click="save(rep, validate)"
            >
              Save
            </v-btn>
          </template>
        </rep-form>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import RepCard from '@/components/representative/RepCard.vue'
import RepForm from '@/components/representative/RepForm.vue'

export default {
  name: 'Profile',
  components: {
    'rep-card': RepCard,
    'rep-form': RepForm
  },
  data() {
    return {
      userInfo: {},
      infoEditDialog: false,
    }
  },
  computed: {
    heading() {
      return 'My Profile'
    },
    subheading() {
      return ''
    },
    ...mapState([
      'user',
    ])
  },
  created() {
    this.$store.dispatch('representatives/one', this.user.userId)
      .then(data => {
        this.userInfo = data
      })
  },
  methods: {
    save(rep, validate) {
      if(!validate()) { return }
      this.$store.dispatch('representatives/update', rep)
        .then(() => {
          this.$store.dispatch('showMessage', { state: 'success', message: 'Your profile info have been updated' })
          this.infoEditDialog = false
          this.userInfo = rep
        })
        .catch(() => this.$store.dispatch('showMessage', { state: 'error', message: 'Something went wrong, we were unable to update your info' }))
    }
  }
}
</script>

<style>

</style>