var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Representative Form")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"First Name","rules":[
                _vm.rules.required,
                _vm.rules.length(0, 20)
              ],"counter":20},model:{value:(_vm.rep.firstName),callback:function ($$v) {_vm.$set(_vm.rep, "firstName", $$v)},expression:"rep.firstName"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-text-field',{attrs:{"label":"Last Name","rules":[
                _vm.rules.required,
                _vm.rules.length(0, 20)
              ],"counter":20},model:{value:(_vm.rep.lastName),callback:function ($$v) {_vm.$set(_vm.rep, "lastName", $$v)},expression:"rep.lastName"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-text-field',{attrs:{"label":"Phone","rules":[
                _vm.rules.required
              ]},model:{value:(_vm.rep.phone),callback:function ($$v) {_vm.$set(_vm.rep, "phone", $$v)},expression:"rep.phone"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_vm._t("action",null,{"rep":_vm.rep,"validate":_vm.validate})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }