<template>
  <div>
    <v-card v-if="mode ==='deco'">
      <v-layout
        column
        fill-height
      >
        <v-card-title>
          <v-spacer />
        </v-card-title>
        <v-spacer />

        <v-card-title class="">
          <div class="display-1 pl-5 pt-5">
            {{ `${ capFirst(rep.firstName) } ${ capFirst(rep.lastName) }` }}
          </div>
        </v-card-title>
      </v-layout>
      <!-- </v-img> -->

      <v-list two-line>
        <v-list-tile>
          <v-list-tile-action>
            <v-icon color="indigo">
              phone
            </v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ rep.phone }}</v-list-tile-title>
            <v-list-tile-sub-title>Mobile</v-list-tile-sub-title>
          </v-list-tile-content>

          <!-- <v-list-tile-action>
            <v-icon>chat</v-icon>
          </v-list-tile-action> -->
        </v-list-tile>

        <v-divider inset />

        <v-list-tile>
          <v-list-tile-action>
            <v-icon color="indigo">
              mail
            </v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ rep.email }}</v-list-tile-title>
            <v-list-tile-sub-title>Personal</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <slot name="action" />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <div>
          <span class="grey--text">ID: {{ rep.repId }}</span><br>
          <span>Name: {{ rep.firstName }} {{ rep.lastName }}</span><br>
          <span>Email: {{ rep.email }}</span><br>
          <span>Phone: {{ rep.phone }}</span>
        </div>
      </v-card-title>
      <v-card-actions v-if="!rep.status">
        <slot name="action" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'RepCard',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'simple'
    },
    rep: {
      type: Object,
      required: true,
    }
  },
  methods: {
    capFirst(v) {
      return v ? v.charAt(0).toUpperCase() + v.slice(1) : ''
    },
  },
}
</script>

<style>

</style>
